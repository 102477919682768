const ENDPOINT = "https://api.sportmarket.club"

export const getStream = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/client/stream/get?token=${token}`)
    const res = await req.json()
    return res
}

export const checkToken = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/client/checkToken?token=${token}`)
    const res = await req.json()

    return res
}


export const get = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/client/getData?token=${token}`)
    const res = await req.json()

    return res
}
export const readMail = async (token) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/client/mail/read`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token
        })
    })
    const res = await req.json()

    return res
}
export const delMail = async (token, id) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/client/mail/del`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            id
        })
    })
    const res = await req.json()

    return res
}

export const buyText = async (token, id) => {
    const req = await fetch(`${ENDPOINT}/api/client/buyText/${id}?token=${token}`)
    const res = await req.json()

    return res
}

export const buyScout = async (token, id) => {
    const req = await fetch(`${ENDPOINT}/api/client/buyScout/${id}?token=${token}`)
    const res = await req.json()

    return res
}

export const topUp = async (amount, token) => {
    const req = await fetch(`${ENDPOINT}/api/client/topup`, {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            amount
        })
    })
    const res = await req.json()

    return res
}

export const getEvents = async (token) => {
    const req = await fetch(`https://weddbets.com/api/evento/cache?_limit=100`, {
        method: "GET",
        headers: {
            "content-type": "application/json"
        }
    })
    const res = await req.json()
    return res
}
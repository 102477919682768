import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { io } from "socket.io-client"
import { getStream } from "../api"
import Header from "./Components/Header"
import Menu from "./Components/Menu"
import PrimaryButton from './Components/PrimaryButton'


const Stream = () => {
    const nav = useNavigate();
    const dispatch = useDispatch()
    const token = localStorage.getItem("client-token")
    const [stream, setStream] = useState(null)
    const [canSee, setCanSee] = useState(true)
    const [showStreamButton, setShowStreamButton] = useState(false)
    const {data, loading} = useSelector(state => state.user)

    const [connected, setConnected] = useState(null);
    const [connectedSocket, setConnectedSocket] = useState();

    async function fetchData() {
        const _data = await dispatch(getStream());
        setStream(_data.stream)
    }

    useEffect(() => {
        if (data.stream) {
            setStream(data.stream)
        }
    }, [data]);

    useEffect(() => {
        if (stream && stream.status == 1) {
            if (data.balance < stream.price / 60) {
                setCanSee(false);
                setShowStreamButton(false)
            }
        }
    }, [stream, data]);

    const handleStopStream = () => {
        connectedSocket.disconnect();
        setConnectedSocket(null);
        setConnected(null);
    }
    
    const handleStreamClick = () => {
        if (stream && !connected && stream.status == 1) {
            if (data.balance < stream.price / 60) {
                setCanSee(false);
                setShowStreamButton(false)
            }

            const socket = io("https://api.sportmarket.club", {
                query: {
                    token: localStorage.getItem("client-token"),
                    stream: stream._id,
                },
            });
    
            socket.on("disconnect", () => {
                setConnectedSocket(null);
                setConnected(null);
            });
    
            socket.on("connect", () => {
                setConnected(stream);
                setConnectedSocket(socket);
            });

            socket.on("streamData", (payload) => {
                setConnected(payload);
            });
        }
    }
    useEffect(() => {
        if (stream && !connected && stream.status == 1) {
            setShowStreamButton(true)
        }else{
            setShowStreamButton(false)
        }
    }, [stream, connected]);

    return (
        <>

            <div className="matches">
                <Menu/>
                <section className={"ms-5 me-5"} style={{width: "1000px"}}>
                    <Header title="Stream"/>

                    <div className="mb-3 text-white  text-center items-center justify-center mt-5 py-3 px-3 w-full flex">

                    
                    
                    {stream && stream.status == 1 ? 
                        canSee ?
                            connected ?
                            (
                                <div className='fixed left-0 top-0 bottom-0 right-0 bg-black bg-opacity-75 flex flex-col items-center justify-center '>
                                    <div className='w-full flex justify-center'>
                                    <ReactPlayer
                                    //
                                        playsinline // extremely crucial prop
                                        pip={false}
                                        light={false}
                                        controls={true}
                                        muted={true}
                                        playing={true}
                                        //
                                        url={"https://stream.sportmarket.club/dash/obs_stream.mpd"}
                                        
                                        onError={(err) => {
                                        console.log(err, "participant video error");
                                        }}
                                    />
                                    </div>
                                    <div className='mt-5'>
                                    <PrimaryButton onClick={handleStopStream}>Отключиться</PrimaryButton>
                                    </div>
                                </div>
                            )
                            : showStreamButton ? (
                                <div>
                                    <div className='mb-3'>Цена за просмотр {stream.price}руб. за минуту</div>
                                    <PrimaryButton onClick={handleStreamClick}>Подключиться к стриму</PrimaryButton>
                                </div>
                            ) : 
                            (
                                <div>
                                    <div>Подключение к стриму <FontAwesomeIcon icon={faSpinner} spinPulse /></div>
                                </div>
                            )
                     : (
                        <div>
<div className='mt-7 mb-3 font-bold text-2xl'>Недостаточно средств для просмотра!</div>
<div className=''>Цена за просмотр {stream.price}руб. за минуту</div>
                        </div>
                    )
                    : (
                        <div>
                            
<img src="/pngwing.com.png" className="mx-auto" width="500px" alt="" />
<div className=''>Нет активного стрима.</div>
                        </div>
                    )
                }
                    </div>
            </section>
        </div>
    </>
    );
};

export default Stream;